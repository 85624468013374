import { useState } from "react";
import "./repo-select.scss";
import { Repo, Authorization, AuthorizationProvider, Organization } from "models/settings";
import GitProviderSelect from "components/git-provider-select";
import { some, sortBy, uniqBy } from "lodash";
import { observer } from "mobx-react-lite";
import { ModalStore, SettingsStore } from "stores";
import { Modal, Icon, Button } from "semantic-ui-react";
import Spinner from "components/spinner";

const RepoSelect = observer(() => {
  const { repoSelect } = ModalStore;
  const [authorization, setAuthorization] = useState(null as Authorization);
  const [repos, setRepos] = useState(null as Repo[]);
  const [suborgs, setSuborgs] = useState(null as Organization[]);
  const [subOrg, setSubOrg] = useState(null as Organization);

  repoSelect.onShown = async () => {
    setAuthorization(null);
    setRepos(null);
    setSuborgs(null);
    await SettingsStore.loadAuthorizations();
  };

  const updateProvider = async (newAuthorization: Authorization) => {
    setAuthorization(newAuthorization);
    setRepos(null);
    setRepos(
      sortBy(
        uniqBy(await SettingsStore.loadRepos(newAuthorization.id), (r) => `${r.owner}/${r.repo}`),
        ["owner", "repo"],
      ),
    );
    if (newAuthorization.provider == AuthorizationProvider.Gitlab) {
      const subs = await SettingsStore.loadSubOrganizations(newAuthorization.id, newAuthorization.identifier)
      setSubOrg(subs && subs.length > 0 ? subs[0] : null);
      setSuborgs(subs);
    }
  };

  const updateSubOrg = async (org: Organization) => {
    setSubOrg(org);
    setRepos(null);
    setRepos(
      sortBy(
        uniqBy(await SettingsStore.loadSubOrgRepos(authorization.id, authorization.identifier, org.key), (r) => `${r.owner}/${r.repo}`),
        ["owner", "repo"],
      ),
    );
  };

  let multipleOrgs = false;

  if (repos && repos.length > 0) {
    const org = repos[0].owner;

    multipleOrgs = some(repos, (r) => r.owner != org);
  }

  return (
    <Modal open={repoSelect.showing}>
      <Modal.Header>Select Repository</Modal.Header>
      <Modal.Content scrolling>
        <div className="repo-select">
          <GitProviderSelect onChange={updateProvider} value={authorization} />
          {authorization && authorization.provider == AuthorizationProvider.Gitlab && (
            <>
            <hr />
            <div className="suborgs">
              {suborgs &&
                suborgs.length > 0 &&
                suborgs.map((o, i) => (
                  <div className={`repo ${subOrg && (subOrg.key == o.key) ? "active" : ""}`} key={i} onClick={() => updateSubOrg(o)}>{o.key}</div>
              ))}
              {!suborgs && <Spinner text="Loading Sub Projects" />}  
            </div>
            </>
          )}
          {SettingsStore.authorizations.length > 0 && (
            <div>
              <hr />
              <div className="repos">
                {repos &&
                  repos.length > 0 &&
                  repos.map((repo, i) => (
                    <div className="repo" key={i} onClick={() => repoSelect.hide(repo)}>
                      {multipleOrgs ? (
                        <span>
                          <span className="repo-owner">{repo.owner}</span>/{repo.repo}
                        </span>
                      ) : (
                        repo.repo
                      )}
                    </div>
                  ))}
                {repos && repos.length == 0 && <h3>No repositories found</h3>}
                {!repos && <Spinner text="Loading Repositories" />}
              </div>
            </div>
          )}
        </div>
      </Modal.Content>
      <Modal.Actions>
        <Button onClick={() => repoSelect.hide()}>
          <Icon name="times" />
          Cancel
        </Button>
      </Modal.Actions>
    </Modal>
  );
});

export default RepoSelect;
