import {
  Alert,
  AuditLog,
  Authorization,
  AuthorizationProvider,
  Environment,
  Organization,
  Repo,
  RunnerConfiguration,
} from "models/settings";
import { action, computed, observable, runInAction } from "mobx";
import { sortBy } from "lodash";
import API from "core/api";
import EnvironmentForm from "models/forms/environment-form";
import AlertForm from "models/forms/alert-form";
import { ModalStoreClass } from "./modal-store";
import { AccountSetupStep } from "../models/account-setup";

export class SettingsStoreClass {
  private readonly ModalStore: ModalStoreClass;
  @observable alerts: Alert[] = [];
  @observable alertsLoaded = false;
  @observable environments: Environment[] = [];
  @observable environmentsLoaded = false;
  @observable authorizations: Authorization[] = [];
  @observable authorizationsLoaded = false;
  @observable runnerConfigurationsLoaded = false;
  @observable runnerConfigurations: RunnerConfiguration[] = [];
  @observable accountSetupSteps: Array<AccountSetupStep> = [];
  @observable environmentAuditLogItems: Array<AuditLog>;
  @observable environmentAuditLogLoaded = false;

  constructor(ModalStore: ModalStoreClass) {
    this.ModalStore = ModalStore;
  }

  @action
  clear() {
    this.alerts = [];
    this.alertsLoaded = false;
    this.environments = [];
    this.environmentsLoaded = false;
    this.authorizations = [];
    this.authorizationsLoaded = false;
    this.runnerConfigurationsLoaded = false;
    this.accountSetupSteps = [];
  }

  @action
  async ensureAlerts() {
    return this.alertsLoaded ? this.alerts : this.loadAlerts();
  }

  @action
  async loadAlerts() {
    const result = await API.getList("/alerts", Alert);
    const alerts = result.data;
    runInAction(() => {
      this.alerts = alerts;
      this.alertsLoaded = true;
    });
    return alerts;
  }

  getAlert(id: number) {
    return this.alerts.find((a) => a.id == id);
  }

  @action
  async deleteAlert(id: number) {
    await API.delete(`/alerts/${id}`);
    runInAction(() => {
      this.alerts = this.alerts.filter((a) => a.id != id);
    });
  }

  @action
  async saveAlert(form: AlertForm) {
    return form.submit(async (alert) => {
      let updated: Alert;
      if (alert.id) {
        const index = this.alerts.findIndex((a) => a.id == alert.id);
        const result = await API.put(`/alerts/${alert.id}`, alert, Alert);
        updated = result.data;
        runInAction(() => {
          this.alerts[index] = updated;
        });
      } else {
        const result = await API.post(`/alerts`, alert, Alert);
        updated = result.data;
        runInAction(() => {
          this.alerts = [...this.alerts, updated];
        });
      }

      return updated;
    });
  }

  @action
  async ensureEnvironments() {
    return this.environmentsLoaded ? this.environments : this.loadEnvironments();
  }

  @action
  async loadEnvironments() {
    const result = await API.getList("/environments", Environment);
    const environments = result.data;
    runInAction(() => {
      this.environments = environments;
      this.environmentsLoaded = true;
    });
    return environments;
  }

  async loadEnvironmentAuditLog(environmentId: number) {
    const result = await API.getList(`environments/${environmentId}/auditTrail`, AuditLog);

    return runInAction(() => {
      this.environmentAuditLogLoaded = true;
      this.environmentAuditLogItems = result.data;
    });
  }

  findEnvironment(id: number) {
    return this.environments.find((a) => a.id == id);
  }

  @computed
  get activeEnvironments() {
    return sortBy(
      this.environments.filter((e) => !e.archived),
      (e) => e.name.toLowerCase(),
    );
  }

  @computed
  get hasEnvironmentWithNoPipelineStage() {
    return !!this.activeEnvironments.find((e) => e.pipelineStageId == null);
  }

  @computed
  get hasEnvironmentWithPipelineStage() {
    return !!this.activeEnvironments.find((e) => e.pipelineStageId != null);
  }

  @action
  async deleteEnvironment(id: number) {
    await API.delete(`/environments/${id}`);
    runInAction(() => (this.environments = this.environments.filter((a) => a.id != id)));
  }

  @action
  async saveEnvironment(form: EnvironmentForm): Promise<boolean> {
    let success = true;

    await form.submit(async (environment) => {
      let updated: Environment;
      if (environment.id) {
        const index = this.environments.findIndex((a) => a.id == environment.id);
        await API.patch(`/environments/${environment.id}`, environment, Environment)
          .then(response => {
            updated = response.data;
            runInAction(() => (this.environments[index] = updated));
          })
          .catch(error => {
            success = false;
            console.error(error);
          });
      } else {
        await API.post(`/environments`, environment, Environment)
          .then(response => {
            updated = response.data;
            runInAction(() => (this.environments = [...this.environments, updated]));
          })
          .catch(error => {
            success = false;
            console.error(error);
          });
      }
    });

    return success;
  }

  @action
  async loadAuthorizations() {
    const result = await API.getList("/authorizations", Authorization);
    const auths = result.data;
    runInAction(() => {
      this.authorizations = auths;
      this.authorizationsLoaded = true;
    });
    return auths;
  }

  @action
  async loadRunnerConfigurations() {
    const { data } = await API.getList(`/runner-configurations`, RunnerConfiguration);
    runInAction(() => {
      this.runnerConfigurationsLoaded = true;
      this.runnerConfigurations = data;
    });
    return this.runnerConfigurations;
  }

  findRunnerConfiguration(id: number) {
    return this.runnerConfigurations.find((a) => a.id == id);
  }

  activeTestStacks() {
    return this.runnerConfigurations.filter((c) => !c.archived);
  }

  hasAuthNeedingIdentifier() {
    return !!this.authorizations.find((a) => a.needsIdentifier);
  }

  checkAuthsForOrganizationNeeded() {
    const auth = this.authorizations.find((a) => a.needsIdentifier);

    if (auth) {
      this.ModalStore.organizationSelect.show(null, auth);
      return true;
    } else {
      return false;
    }
  }

  @action
  async deleteAuthorization(id: number) {
    await API.delete(`/authorizations/${id}`);
    runInAction(() => (this.authorizations = this.authorizations.filter((a) => a.id != id)));
  }

  @action
  async createAuthorization(
    provider: AuthorizationProvider,
    token: string,
    identifier: string = "",
  ) {
    const result = API.post("/authorizations", { provider, token, identifier }, Authorization);
    const auth = (await result).data;
    runInAction(() => (this.authorizations = [...this.authorizations, auth]));
  }

  @action
  async setAuthorizationIdentifier(id: number, identifier: string) {
    const result = await API.patch(`/authorizations/${id}`, { identifier }, Authorization);
    const newAuth = result.data;
    runInAction(
      () => (this.authorizations = [newAuth, ...this.authorizations.filter((a) => a.id != id)]),
    );
  }

  @action
  async addGitHubInstallation(installationId: number) {
    await API.post(`/authorizations/git-hub-installations/${installationId}`, {});
  }

  async loadRepos(authorizationId: number) {
    const result = await API.getList(`/authorizations/${authorizationId}/repos`, Repo);
    return result.data;
  }

  async loadSubOrgRepos(authorizationId: number, org: string, subOrg: string) {
    const result = await API.getList(
      `/authorizations/${authorizationId}/organizations/${org}/suborganizations/${subOrg}/repos`,
      Repo,
    );
    return result.data
  }

  async loadOrganizations(authorizationId: number) {
    const result = await API.getList(
      `/authorizations/${authorizationId}/organizations`,
      Organization,
    );
    return result.data;
  }

  async loadSubOrganizations(authorizationId: number, organizationId: string) {
    const result = await API.getList(
      `/authorizations/${authorizationId}/organizations/${organizationId}/suborganizations`,
      Organization,
    );
    return result.data;
  }

  async loadApiKey() {
    const result = await API.get("/account/api-key");
    return (result.data as any).key as string;
  }

  async updateApiKey() {
    const result = await API.post("/account/api-key", {});
    return result.data as string;
  }

  @action
  async loadAccountSetupSteps(): Promise<Array<AccountSetupStep>> {
    const result = await API.getList("/account/setup-step", AccountSetupStep);
    runInAction(() => {
      this.accountSetupSteps = result.data;
    });

    return result.data;
  }

  @action.bound
  async createAccountSetupStep(accountSetupStep: AccountSetupStep): Promise<void> {
    await API.post("/account/setup-step", accountSetupStep);
  }
}
